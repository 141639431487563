import React,{useState} from 'react'
import SearchHero from "../components/hero/SearchHero"
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "../components/headers/light";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import ProductCard from '../components/cards/ProductCard';
import Footer from "../components/footers/FiveColumnWithInputForm.js";
const Header = tw(HeaderBase)`max-w-none`;
 ;

function Shop() {
    const [buttonRounded] = useState(true)
    const buttonRoundedCss = buttonRounded && tw`rounded-full`;
    const navLinks = [
        <NavLinks key={1}>
          <NavLink href="/">Home</NavLink>
          <NavLink href="/shop">Shop</NavLink>
          <NavLink href="/forum">Wadi Forum</NavLink>
          <NavLink href="/navigator">Wadi Navigator</NavLink>
          <NavLink href="/#testimonials">Testimonials</NavLink>
        </NavLinks>,
        <NavLinks key={2}>
          <NavLink href="/login" tw="lg:ml-12!">
            Login
          </NavLink>
          <PrimaryLink css={buttonRoundedCss} href="/signup">
            Sign Up
          </PrimaryLink>
        </NavLinks>
      ];
    const [products] = useState([
        {image:"https://image.freepik.com/free-photo/turmeric-powder_1323-401.jpg",price:500,name:"Plaintain Leaves",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"},
        {image:"https://image.freepik.com/free-photo/turmeric-powder_1323-401.jpg",price:500,name:"Plaintain Leaves",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"},
        {image:"https://image.freepik.com/free-photo/turmeric-powder_1323-401.jpg",price:500,name:"Plaintain Leaves",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"},
        {image:"https://image.freepik.com/free-photo/turmeric-powder_1323-401.jpg",price:500,name:"Plaintain Leaves",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"},
        {image:"https://image.freepik.com/free-photo/turmeric-powder_1323-401.jpg",price:500,name:"Plaintain Leaves",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"},
        {image:"https://image.freepik.com/free-photo/turmeric-powder_1323-401.jpg",price:500,name:"Plaintain Leaves",description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"}

    ])



    return (
        <div>
            <Header links={navLinks} className="lg:px-12 p-6" />
            <SearchHero />
            <div className="mb-12">
            <div className="mt-5 lg:mx-12">
           <div className="lg:mx-10 sm:mx-7">
            <p className="mx-4 text-xl">Newest products</p>
            <hr className="mx-4 mt-2" width="80px"/>
            </div>
            <div className="mt-3 lg:mx-12 flex overflow-auto">
            <ProductCard products={products} />
            </div>
            <div className="mt-3 lg:mx-12 flex overflow-auto">
            <ProductCard products={products} />
            </div>
            <div className="mt-3 lg:mx-12 flex overflow-auto">
            <ProductCard products={products} />
            </div>
            <div className="mt-3 lg:mx-12 flex overflow-auto">
            <ProductCard products={products} />
            </div>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Shop
