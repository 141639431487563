import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import {doc,getDoc} from 'firebase/firestore'
import {db} from "../db"



export const getUsers = createAsyncThunk('users/getUsers', async () => {
    const response = await getDoc(doc(db,'users', localStorage.getItem("wadiKey")))
    localStorage.setItem('userData',JSON.stringify(response.data()))
    return response.data()
  })


// export const getUsers = createAsyncThunk('users/getUsers', async (dispatch,getState) => {
//     return await fetch('https://jsonplaceholder.typicode.com/users')
//     .then((res)=>
//         res.json()
//     )
   
//   })

export const usersSlice = createSlice({
  name: "user",
  initialState: {
    users:"",
    status:null,
    index:''
  },
  extraReducers:{
    [getUsers.pending]: (state,action) =>{
        state.status="loading"
    },
    [getUsers.fulfilled]: (state,action) =>{
        state.status = "success";
        state.users = action.payload
    },
    [getUsers.rejected]:(state,action) =>{
        state.status = "failed"

    }
}
});





export default usersSlice.reducer;

