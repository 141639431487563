import React, { Component } from 'react'
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "../../components/headers/light.js";
import tw from "twin.macro";
import {arrayUnion, doc,getDoc,updateDoc} from "firebase/firestore"
import { db } from '../../db.js';
import { UsersIcon } from '@heroicons/react/solid';
import moment from "moment"
// import Skeleton from 'react-loading-skeleton';
// import {CheckIcon} from "@heroicons/react/solid"


const buttonRoundedCss = tw`rounded-full`
const Header = tw(HeaderBase)`max-w-none`;
const Input = tw.input`w-full rounded-xl px-8 py-4 font-medium bg-white border border-gray-200 placeholder-gray-500 shadow-sm text-sm focus:outline-none focus:border-white focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.input`w-full rounded-xl px-8 py-4 font-medium bg-white border border-gray-200 placeholder-gray-500 shadow-sm text-sm focus:outline-none focus:border-white focus:bg-white first:mt-0`;

const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/forum">Wadi Forum</NavLink>
      <NavLink href="/navigator">SendSample</NavLink>
      <NavLink href="/academy">Wadi Academy</NavLink>
      <NavLink href="/#testimonials">Testimonials</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <NavLink target="_blank" href="https://app.wadi.africa/signup" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink target="_blank" css={buttonRoundedCss} href="https://app.wadi.africa/signup">
        Sign Up
      </PrimaryLink>
    </NavLinks>
  ];

export class Video extends Component {
    state = {
        course:{},
        loading:true,
        name:"",
        email:"",
        password:"",
        comment:"",
        verified:false
    }

    componentDidMount(){
        console.log(window.location.pathname.slice(9,-6))
        this.fetchData()
      }

      closeModal(){
        const r = document.getElementById("staticBackdrop")
        r.style.display = 'none'
      }

    async fetchData(){
        console.log(window.location.pathname.slice(9,-6))
        const courseRef = doc(db,'academy',window.location.pathname.slice(9,-6))
        const response = await getDoc(courseRef)
        this.setState({course:response.data()})
    
    }


    checkEmail(){
        if((this.state.course.learners).includes(this.state.email)){
            this.setState({verified:true})
            this.closeModal()
        } else{
            this.closeModal()
        }
    }

    async submitComment(){
        const e = {
            name:this.state.name,
            email:this.state.email,
            date:Date.now(),
            comment:this.state.comment
        }
        const courseRef = doc(db,'academy',window.location.pathname.slice(9,-6))
        updateDoc(courseRef,{
            comments:arrayUnion(e)
        }).then(()=>{
            this.fetchData()
        })
       
    }


    // setAmount(e){
    //  this.setState({currency:e.target.value})
    //  if(e.target.value === "USD"){
    //   this.setState({amount:10})
    //  } 
    //  else if(e.target.value === "GBP"){
    //   this.setState({amount:8})
    //  }

    //  else if(e.target.value === "EUR"){
    //   this.setState({amount:9})
    //  }
    //  else{
    //   this.setState({amount:5000})
    //  }
    // }


  render() {
    return (
      <div>


          <div className="my-4 mx-3">
          <Header links={navLinks} className="lg:px-8" />
          </div>


         {this.state.verified && <div className="max-w-xl mx-auto">
         <iframe title="Wadi Video Player" style={{borderRadius:"20px"}} scrolling="no" frameBorder="0" src="https://drive.google.com/file/d/1AiH-_WK-TaRgqz0sM2H1OXQncMgiVw4G/preview" width="100%" height="315" allow="autoplay"></iframe>
          {/* <iframe width="100%" height="315" src={this.state.course.video+"?&theme=dark&autohide=2&modestbranding=1&showinfo=0&autohide=2"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
          <div className="p-6 flex w-xl mx-auto">
        <div className="flex">
          <UsersIcon className="w-5 mr-2"/>{(this.state.course.learners).length}
          </div>
          </div>
          <div className="px-6">
          <h2 className="font-semibold">Add a Comment:</h2>
          <TextArea style={{height:"100px"}} type="text"
            id="comment"
            onChange={(e)=>this.setState({comment:e.target.value})}
            placeholder="Add a Comment"/>
            <button className="mt-7 my-5 rounded-xl shadow-lg text-white bg-blue-600 w-full rounded-xl px-8 py-4" onClick={()=>this.submitComment()}>Submit comment</button>
            <h2 className="font-semibold">Comments</h2>
            {this.state.course && this.state.course.comments.map((comment,i)=>(
                <div className="shadow-lg flex p-6 mb-5 rounded-lg" key={i}>
                    <div className="mr-3">
                        <img className="w-9 h-9" src="https://firebasestorage.googleapis.com/v0/b/tellbookstore.appspot.com/o/avatar.png?alt=media&token=593af39b-6c6d-47d8-afc5-ab8560b08dd2" alt="avatar"/>
                    </div>

                    <div>
                        <p className="font-bold m-0 p-0">{comment.name}</p>
                         <p style={{fontSize:"8px"}} className="m-0 p-0">{moment(comment.date).format("LLL")}</p>
                        <p>{comment.comment}</p>
                    </div>
                </div>
            ))}
          </div>
          </div>}

          {!this.state.verified && <div>
            <h2 className="text-center text-2xl" style={{marginTop:"120px"}}>Sorry, you cannot watch this video because you are not authorized </h2>
                      </div>}


          <button type="button"
          id="modalButton"
          style={{display:"none"}}
  className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
  data-bs-toggle="modal">
  Launch static backdrop modal
</button>


<div className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
  id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="mx-3 modalWidth modal-dialog relative w-auto pointer-events-none">
    <div
      className="modal-content border-none shadow-sm relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div
        className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 className="text-xl text-center font-medium leading-normal text-gray-800" id="exampleModalLabel">
          Enter your details below 👇🏽:
        </h5>
        <button type="button"
          className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body relative p-4">
      <Input
            type="text"
            id="name"
            onChange={(e)=>this.setState({name:e.target.value})}
            placeholder="Name"
          ></Input>


            <Input
            type="email"
            id="email"
            onChange={(e)=>this.setState({email:e.target.value})}
            placeholder="Email"
          ></Input>

            


          <button onClick={()=>this.checkEmail()} className="mt-7 my-5 rounded-xl shadow-lg text-white bg-blue-600 w-full rounded-xl px-8 py-4">
            Access video
          </button>
      </div>
      <div
        className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
        <button 
          className="inline-block px-6 py-2.5 bg-white outlined border text-blue-600 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
          data-bs-dismiss="modal" onClick={this.closeModal}>Close</button>
    
      </div>
    </div>
  </div>
</div>


      </div>
    )
  }
}

export default Video