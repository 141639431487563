import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import React,{useState} from "react";
import tw from "twin.macro";
import Paystack from "../../components/Paystack/Paystack";

const Input = tw.input`w-full rounded-xl px-8 py-4 font-medium bg-white border border-gray-200 placeholder-gray-500 shadow text-sm focus:outline-none focus:border-white focus:bg-white first:mt-0`;

function BookingForm(props) {
  const [date,setDate] = useState("")
  const [time,setTime] = useState("")
  const [email] = useState("tobilobaojuolape@gmail.com")
  let [hours,setHours] = useState(1)
  const [price,setPrice] = useState(0)

  // function SubmitForm(){
  //     console.log(date,time)
  // }

  return (
    <div style={{maxWidth:"600px",margin:"auto"}}>
    <label>Select Equipment</label>
      {props.data.equipment && (
        <select
          id="equipment"
          type="text"
          className="border-0 px-3 py-5 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Select Equipment"
          onChange={(e)=>setPrice(e.target.value)}
        >
          <option value="" selected="selected">
            Choose Equipment
          </option>
          {props.data.equipment.map((equipment, i) => (
            <option
              selected={equipment.name}
              key={i}
              defaultValue={equipment.name}
              value={equipment.price}
            >
              {equipment.name}
            </option>
          ))}
        </select>
      )}
      <div className="mt-3">
      <label>
          Delivery Date
      </label>
      <Input type="date" onChange={(e)=>setDate(e.target.value)}>
      </Input>
      </div>

      <div className="mt-3">
      <label>
          Pickup Date
      </label>
      <Input type="date" onChange={(e)=>setTime(e.target.value)}>
      </Input>
      </div>

      <div className="mt-4">
          <label>Number of samples</label>
          <div className="flex mt-2 justify-between">
              {hours >= 1 && <button onClick={()=>setHours(hours-1)} className="bg-blue-600 p-3 shadow-lg rounded-full"><MinusIcon className="text-white w-5 h-5"/></button>}
              {hours < 1 && <button  className="bg-blue-600 p-3 shadow-lg rounded-full"><MinusIcon className="text-white w-5 h-5"/></button>}
              <p className="mt-2 text-center">{hours}</p>
              <button onClick={()=>setHours(hours+1)} className="bg-blue-600 p-3 shadow-lg rounded-full"><PlusIcon className="text-white w-5 h-5"/></button>
          </div>
      </div>
     {(price*hours) > 0 && <p className="text-center font-bold text-4xl my-3">NGN {price * hours}</p>}
      <Paystack price={price * hours} email={email} time={time} date={date}/>




    </div>
  );
}

export default BookingForm;
