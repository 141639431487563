import React from 'react'
import "./AssessmentForm.css"
import Flutterwave from "../../Flutterwave/Flutterwave"



function PaymentForm(props) {
  return (
    <div id={props.id}>
        <div className="pt-6" >
            <div className="mx-auto max-w-lg">
           <h2 className="pt-6 text-2xl text-center font-bold">{props.title}</h2>
            <p className=" text-center  text-md">{props.description}</p>
            {props.users && <Flutterwave buttonName={"Make Payment"} userData={{name:props.users.firstName,email:props.users.email,currency:"NGN",amount:50000}} />}
            </div>
        </div>
    </div>
  )
}

export default PaymentForm