import React from 'react';
import "./Style.css"
import {LocationMarkerIcon} from '@heroicons/react/solid'
import { Link } from 'react-router-dom';

function Labs(props) {
  return (
props.data.map((lab,i)=>(
    <div className="m-3" key={i}>
    <div className={'imageCard animate__animated animate__slow animate__fadeInUp animate__delay-'+i+'s'}>
   <img src={lab.image} alt="#" style={{width:"100%",height:"290px",objectFit:"cover",borderRadius:"16px 16px 0px 0px"}}/>
   <div className="cardtext">
   {lab.name.length>= 19 &&<p className="text-2xl">{lab.name.slice(0,19)}...</p>}
   {lab.name.length<= 19 &&<p className="text-2xl">{lab.name}</p>}
    <span className="flex"><LocationMarkerIcon className="w-6 h-6"/> {lab.location}</span>
    <Link to={"/"+lab.slug}>
    <button className="w-full bg-blue-600 text-white rounded-xl p-4 my-3 shadow-lg hover:bg-blue-100 hover:text-blue-600">View Lab</button>
    </Link>
  </div>
 </div>
 </div>
 
)) 
  )
}

export default Labs;
