import { arrayUnion, updateDoc, doc } from "@firebase/firestore";
import React, { Component } from "react";
import { db } from "../../db";
import LoadingOverlay from "react-loading-overlay";

export class Form extends Component {
  state = {
    title: "",
    collaborators: "",
    progress:"0.05",
    isActive:false
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.setState({isActive:true})
    const researchPayLoad = {
      title: this.state.title,
      collaborators: this.state.collaborators,
      progress: this.state.progress,
    };

    const docRef = doc(db,'users', localStorage.getItem("wadiKey"));
    updateDoc(docRef, {
      research: arrayUnion(researchPayLoad),
    }).then(() => {
      this.setState({isActive:true})
      window.location.reload();
    });
  };

  render() {
    return (
      <LoadingOverlay active={this.state.isActive} spinner text="Loading...">
        <p>.</p>
        <>
          <div>
            <div className="">
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form className="mb-6">
                  <div className="flex relative w-full mb-9">
                    <div className="w-full h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Project Title
                      </label>
                      <input
                        id="title"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Project Title"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="flex relative w-full mb-9">
                    <div className="w-full h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Collaborators
                      </label>
                      <input
                        id="collaborators"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Collaborators"
                        onChange={this.handleChange}
                      />
                    </div> 
                  </div>

                  {/* <div className="flex relative w-full mb-9">
                    <div className="w-1/2 h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Department
                      </label>
                      <input
                        id="department"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="department"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="w-1/2 h-12">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Role
                      </label>
                      <input
                        id="role"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="role"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div> */}
                </form>
                <div className="text-center">
                    <button
                      className="mt-4 bg-blue-500 text-white hover:bg-blue-900 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </>
      </LoadingOverlay>
    );
  }
}

export default Form;
