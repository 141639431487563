import React from 'react'
import "./AssessmentForm.css"
import Clock from "../../../images/clock.gif"


function AssessmentForm(props) {
  return (
    <div id={props.id}>
        <div >
            <div className="mx-auto max-w-lg">
           <img src={Clock} alt="clock" className="imageSize"/>
           <h2 className="text-2xl text-center font-bold">{props.title}</h2>
            <p className="text-center  text-md">{props.description}</p>
            </div>
        </div>
    </div>
  )
}

export default AssessmentForm