import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/user";
import { auth } from "../../firebase-auth";
import Steppers from "../../components/forms/DissertationForm/Steppers"
import './Style.css'
import './AddProject.css'
import UploadDissertation from "../../components/forms/DissertationForm/UploadDissertation"
import AssessmentForm from "../../components/forms/DissertationForm/AssessmentForm"
import PaymentForm from "../../components/forms/DissertationForm/PaymentForm";
import ManuscriptReady from "../../components/forms/DissertationForm/ManuscriptReady"


function Dashboard(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [step,setStep] = useState(1)
  const { users } = useSelector((state) => state.users);
  const [projectDetails] = useState(JSON.parse(localStorage.getItem('project')))
 

  

  function nextStep(){
    let detailForm = document.getElementById("detailForm")
    let preliminaryScreen = document.getElementById("preliminaryScreen")
    detailForm.style.display = "none"
    preliminaryScreen.style.display= "block"
    setStep(step+1)
  }

  function fetchStep(){
    let dissertationForm = document.getElementById("dissertationForm")
    let assessmentScreen = document.getElementById("assessmentScreen")
    let assessmentComplete = document.getElementById("assessmentComplete")
    let paymentScreen = document.getElementById("paymentScreen")
    let writingProgress = document.getElementById("writingProgress")
    let manuscriptReady = document.getElementById("manuscriptReady")
   
    dissertationForm.style.display = projectDetails.step===1?"block":"none"
    assessmentScreen.style.display=  projectDetails.step===2?"block":"none"
    assessmentComplete.style.display= projectDetails.step===3?"block":"none"
    paymentScreen.style.display = projectDetails.step===4?"block":"none"
    writingProgress.style.display = projectDetails.step===5?"block":"none"
    manuscriptReady.style.display = projectDetails.step===6?"block":"none"
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    fetchStep()
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.push("/login");
      }
    });
  });



  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        users={users}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          users={users}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <div className="my-8">
        <div>
          <Steppers  projectDetails={projectDetails} step={projectDetails?projectDetails.step:step} />
          </div>

          <div className="formContainer">
          <div><UploadDissertation id={"dissertationForm"} projectDetails={projectDetails} users={users} nextStep={nextStep}/></div>

          
        {/* Step 2 */}
        <AssessmentForm  id={"assessmentScreen"} title={"Dissertation undergoing assessment for publishability"} description={`"Hi ${users.firstName}, your dissertation was uploaded successfully and is currently ongoing review for publishability. This is the second step. You will receiv an email from us to move to the next step"`}/>


         {/* Step 3 */}
         <AssessmentForm id="assessmentComplete" title={"Congratulations, your assessment has been completed"} description={`"Hi  ${users.firstName}, your dissertation assessment is completed. Kindly click ${<button onClick={()=>nextStep('payment')}>here</button>} to proceed to make payment for the manuscript writing service" `}/>
         
          {/* Step 4*/}
          <PaymentForm users={users} id="paymentScreen" title={"Make Payment to proceed"} description={`"Hi ${users.firstName}, your dissertation assessment is completed. Kindly tap the button below to proceed to make payment for the manuscript writing service" `}/>


          {/* Step 5*/}
          <AssessmentForm id="writingProgress" title={"Your Manuscript writing has been assigned to a Ghostwriter"} description={`"Hi ${users.firstName}, the writing of your dissertation into a publishable manuscript has been assigned to one of our ghost writers. We would send you an email when it is ready!" `}/>
         

         {/* Step 6*/}
         <ManuscriptReady id="manuscriptReady" title={"Your Manuscript is ready for Download!"} description={`"Hi ${users.firstName}, your manuscript is ready for download. Kindly tap the button below to download it. Thank you for riding with Wadi" `}/>



          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
