import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import Hero from "../components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "../components/features/ThreeColWithSideImage.js";
import MainFeature from "../components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "../components/features/ThreeColCenteredStatsPrimaryBackground.js";
// import Pricing from "../components/pricing/TwoPlansWithDurationSwitcher.js";
// import Blog from "../components/blogs/GridWithFeaturedPost.js";
import Testimonial from "../components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "../components/faqs/SingleCol.js";
import GetStarted from "../components/cta/GetStartedLight.js";
import Footer from "../components/footers/FiveColumnWithInputForm.js";
import WhatsApp from "../components/WhatsApp/WhatsApp.js";




export default function Home() {
  return (
    <AnimationRevealPage>
      <WhatsApp />
      <Hero />
      <FeatureStats/>
      <Features 
        heading={<>Explore Wadi's Features</>}
      />
      <MainFeature
        heading={<>Cloud built by and for Professionals</>}
      />
      <Testimonial 
        heading={<>Our Clients Love Us</>}
      />
      {/* <Pricing 
        heading={<>Flexible Plans</>}
      /> */}
      <FAQ
        heading={<>Any Questions ?</>}
      />
      {/* <Blog
        subheading="Blog"
        heading={<>We love Writing</>}
      /> */}
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
