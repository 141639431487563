import "./styles/output.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./demos/ServiceLandingPage"
import Signup from "./pages/Signup"
import Login from "./pages/Login"
import VendorSignup from "./pages/Vendors/Auth/Signup"
import VendorLogin from "./pages/Vendors/Auth/Login"
import VendorDashboard from "./pages/Vendors/Dashboard"
import Dashboard from "./pages/Dashboard"
import ForgotPassword from './pages/ForgotPassword'
import Shop from "./pages/Shop"
import Test from "./pages/Test"
import Product from "./pages/Product"
import Forum from "./pages/Forum/Forum"
import PublishIt from "./pages/Manager/PublishIt"
import Academy from "./pages/Academy/Academy"
import CoursePage from "./pages/Academy/Course"
import Video from "./pages/Academy/Video"
import Navigator from "./pages/Navigator/Navigator"
import BookLab from "./pages/Navigator/BookLab";   
import QuestionPage from "./pages/Forum/QuestionPage"
import ResearchManager from "./pages/Manager/Manager"
import DataAnalysis from "./pages/DataAnalysis";
import Events from "./pages/Events";
import CategoryForum from "./pages/Forum/CategoryForum"
import "@fortawesome/fontawesome-free/css/all.min.css";
import NewDashboard from "./pages/NewDashboard/NewDashboard";
import AddProject from "./pages/Manager/AddProject";
import SingleProject from "./pages/Manager/SingleProject";
import Dissertation from "./pages/Manager/Dissertation"
import SingleDissertation from "./pages/Manager/SingleDissertation"
import PublishItApp from "./pages/PublishIT/PublishIT";

export default function App() {
  return (
    <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route path="/signup" component={Signup} />
      <Route path="/login" component={Login} />
      <Route path="/vendor/signup" component={VendorSignup} />
      <Route path="/vendor/login" component={VendorLogin} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/vendor/dashboard" component={VendorDashboard} />
      <Route path="/data-analysis" component={DataAnalysis} />
      <Route path="/research-manager" component={ResearchManager} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/new-dashboard" component={NewDashboard} />
      <Route path="/shop" component={Shop} />
      <Route path="/test" component={Test}/>
      <Route path="/forum" exact component={Forum} />
      <Route path="/publishit" component={PublishItApp}/>
      <Route path="/publish-it" component={PublishIt}/>
      <Route path="/add-project" component={AddProject}/>
      <Route path="/dissertation" component={Dissertation}/>
      <Route path="/academy" exact component={Academy} />
      <Route path="/academy/:id/video" exact component={Video} />
      <Route path="/academy/:id" component={CoursePage} />
      <Route path="/navigator" exact component={Navigator} />
      <Route path="/events" exact component={Events} />
      <Route path="/:id" exact component={BookLab} />
      <Route path="/product/:id" component={Product} />
      <Route path="/forum/:id" component={QuestionPage} />
      <Route path="/category/:id" component={CategoryForum} />
      <Route path="/project/dissertation/:id" component={SingleDissertation} />
      <Route path="/project/:id" component={SingleProject} />
     
    </Switch>
  </BrowserRouter>
  );
}

