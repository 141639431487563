import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/user";
import { auth } from "../../firebase-auth";
import Steppers from "../../components/forms/ReviewForm/Steppers";
import "./Style.css";
import "./AddProject.css";
import DetailsForm from "../../components/forms/ReviewForm/DetailsForm";
import PreliminaryForm from "../../components/forms/ReviewForm/PreliminaryForm";
import PaymentForm from "../../components/forms/ReviewForm/PaymentForm";
import ManuscriptReady from "../../components/forms/DissertationForm/ManuscriptReady";

function Dashboard(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [step, setStep] = useState(1);
  const { users } = useSelector((state) => state.users);
  //   const [modalIsOpen, setIsOpen] = React.useState(false);
  //   const [deletemodalIsOpen, setdeleteModal] = React.useState(false);
  const [projectDetails] = useState(
    JSON.parse(localStorage.getItem("project"))
  );

  function nextStep() {
    let detailForm = document.getElementById("detailForm");
    let preliminaryScreen = document.getElementById("preliminaryScreen");
    detailForm.style.display = "none";
    preliminaryScreen.style.display = "block";
    setStep(step + 1);
  }

  function fetchStep() {
    let detailsForm = document.getElementById("details");
    let review = document.getElementById("review");
    let payment = document.getElementById("payment");
    let manuscriptReady = document.getElementById("manuscriptReady");

    detailsForm.style.display = projectDetails.step === 1 ? "block" : "none";
    payment.style.display = projectDetails.step === 2 ? "block" : "none";
    review.style.display = projectDetails.step === 3 ? "block" : "none";
    manuscriptReady.style.display =
      projectDetails.step === 4 ? "block" : "none";
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    fetchStep(1);
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.push("/login");
      }
    });
  });

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        users={users}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          users={users}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <div className="my-8">
          <div>
            <Steppers
              projectDetails={projectDetails}
              step={projectDetails ? projectDetails.step : step}
            />
          </div>

          <div className="formContainer">
            {/* Step 1 */}
            <div>
              <DetailsForm id={"details"} users={users} nextStep={nextStep} />
            </div>

            {/* Step 2 */}
            <PaymentForm
              users={users}
              id={"payment"}
              title={"Make Payment to proceed"}
              description={`"Hi ${users.firstName}, your manuscript's primary assessment is completed. Kindly tap the button below to proceed to make payment for the manuscript writing service" `}
            />

            {/* Step 3 */}
            <PreliminaryForm
              id={"review"}
              title={"Your Manuscript is under review"}
              description={
                "Your  manuscript has been received and is under review. You will receive an email notification when you need to move to the next stage"
              }
            />

            {/* Step 4 */}
            <ManuscriptReady
              id="manuscriptReady"
              title={"Your Manuscript is ready for Download!"}
              description={`"Hi ${users.firstName}, your manuscript is ready for download. Kindly tap the button below to download it. Thank you for riding with Wadi" `}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
