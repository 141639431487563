import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/user";
import { auth } from "../../firebase-auth";
import Steppers from "../../components/forms/ReviewForm/Steppers"
import './Style.css'
import './AddProject.css'
import DetailsForm from "../../components/forms/ReviewForm/DetailsForm"
import PreliminaryForm from "../../components/forms/ReviewForm/PreliminaryForm";
import PaymentForm from "../../components/forms/ReviewForm/PaymentForm";
import ManuscriptReady from "../../components/forms/DissertationForm/ManuscriptReady"

function Dashboard(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [step,setStep] = useState(1)
  const { users } = useSelector((state) => state.users);

  let detailsForm = document.getElementById("details")
  let review = document.getElementById("review")
  let payment = document.getElementById("payment")
  let manuscriptReady = document.getElementById("manuscriptReady")
 


  function nextStep(e){
    setStep(step+1)
  
    detailsForm.style.display = e===1?"block":"none"
    payment.style.display = e===2?"block":"none"
    review.style.display=  e===3?"block":"none"
    manuscriptReady.style.display = e===4?"block":"none"
    
  }


  function startStep(e){
    let detailsForm = document.getElementById("details")
    let review = document.getElementById("review")
    let payment = document.getElementById("payment")
    let manuscriptReady = document.getElementById("manuscriptReady")
   
  

    detailsForm.style.display = e===1?"block":"none"
    payment.style.display= e===2?"block":"none"
    review.style.display=  e===3?"block":"none"
    manuscriptReady.style.display = e===4?"block":"none"
  }





  const dispatch = useDispatch();
  useEffect(() => {
    // start step with 1
    startStep(1)
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    


    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.push("/login");
      }
    });
  },[props.history]);



  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        users={users}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          users={users}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <div className="my-8">
          <div>
          <Steppers step={step} />
          </div>

          <div className="formContainer">


          {/* Step 1 */}
          <div><DetailsForm id={"details"}  users={users} nextStep={nextStep}/></div>

          {/* Step 2 */}
          <PaymentForm users={users} id={"payment"} title={"Make Payment to proceed"} description={`"Hi ${users.firstName}, your manuscript's primary assessment is completed. Kindly tap the button below to proceed to make payment for the manuscript writing service" `}/>


           {/* Step 3 */}
          <PreliminaryForm id={"review"} title={"Your Manuscript is under review"} description={"Your  manuscript has been received and is under review. You will receive an email notification when you need to move to the next stage"} />


         {/* Step 4 */}
          <ManuscriptReady id="manuscriptReady" title={"Your Manuscript is ready for Download!"} description={`"Hi ${users.firstName}, your manuscript is ready for download. Kindly tap the button below to download it. Thank you for riding with Wadi" `}/>
          </div>   
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
