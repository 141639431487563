import React from "react";
import "./WhatsApp.css"
import {BsWhatsapp} from "react-icons/bs"

  function WhatsApp() {
    const goToWhatsApp = () =>{
        window.open("https://api.whatsapp.com/send?phone=2348133416455&text=Hi%20Wadi.%20I%20would%20like%20to%20inquire%20about%20your%20products%20and%20services.%20My%20name%20is%20","_blank")
    }


    return (
        <div onClick={goToWhatsApp} className="whatsAppButton floating-element">
            <BsWhatsapp color="white" size={"30px"} />
        </div>
    )
  }


export default WhatsApp