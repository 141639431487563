import React from 'react';
import { PaystackConsumer } from 'react-paystack';
// import {db} from "../../db"
// import {updateDoc, doc } from "@firebase/firestore";

  

  
 

  // you can call this function anything
  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    alert('Payment not completed')
  }

  function Paystack(props) {
    const config = {
      reference: (new Date()).getTime().toString(),
      email: props.email,
      amount: props.price*100,
      publicKey: 'pk_live_d9516c4c9ed7efe222ec1a27c8eb5ad049d2eeab',
  };
      const componentProps = {
          ...config,
          text: 'Paystack Button Implementation',
          onSuccess: (reference) => handleSuccess(reference),
          onClose: handleClose
      };

       // you can call this function anything
  const handleSuccess = (reference) => {
    // const docRef = doc(db, 'company', localStorage.getItem("fisitorKey"));
    // updateDoc(docRef, {
    //   pricingPlan:props.plan,
    //   expiryDate:Date.now() + 2629800000
    // }).then(() => {
    //   window.location.href = "/admin/dashboard"
    // });

    console.log(reference);
  };
  
    return (
        <PaystackConsumer {...componentProps} >
          {({initializePayment}) => 
          <button className="bg-blue-600 shadow-lg p-4 w-full rounded-lg mt-4 text-white" onClick={() => initializePayment(handleSuccess, handleClose)}><span>Place Order</span></button>}
        </PaystackConsumer>
    );
  }
  
  export default Paystack;