import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Hero from "../components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "../components/features/VerticalWithAlternateImageAndText.js";
import Blog from "../components/blogs/ThreeColSimpleWithImage.js";
// import Testimonial from "../components/testimonials/TwoColumnWithImage.js";
import ContactUsForm from "../components/forms/SimpleContactUs.js";
import Footer from "../components/footers/FiveColumnWithInputForm.js";
import Pricing from "../components/pricing/TwoPlansWithDurationSwitcher"
import WhatsApp from "../components/WhatsApp/WhatsApp";

 const EventLandingPage = () => (
  <AnimationRevealPage>
     <WhatsApp />
    <Hero />
    <Features />
    <Blog />
    {/* <Testimonial /> */}
    <Pricing />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);


export default EventLandingPage 
