import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function LoadingVisitorsTable() {
  return (
        <tbody className="bg-white">
          <tr className="text-gray-700">
            <td className="px-4 py-3 ">
              <div className="flex items-center text-sm">
                <div className="relative w-8 h-8 mr-3 rounded-full md:block">
                  <div
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  ></div>
                </div>
                <div>
                  <p
                    style={{ fontSize: "13px", lineHeight: "13px",marginBottom:"4px" }}
                    className="text-black"
                  >
                   <Skeleton />
                  </p>
                  <p style={{fontSize:"10px",lineHeight:"13px"}} className="text-white">Ewwwwwwe</p>
                </div>
              </div>
            </td>
            <td className="px-4 py-3 text-xs font-semibold ">
              <Skeleton />
            </td>
            <td className="px-4 py-3 text-xs "><Skeleton /></td>
            <td className="px-4 py-3 text-xs ">
              <span>
              
              <Skeleton />
              </span>
            </td>
            <td className="px-4 py-3 text-sm ">
              <button>
               <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr className="text-gray-700">
            <td className="px-4 py-3 ">
              <div className="flex items-center text-sm">
                <div className="relative w-8 h-8 mr-3 rounded-full md:block">
                  <div
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  ></div>
                </div>
                <div>
                  <p
                    style={{ fontSize: "13px", lineHeight: "13px",marginBottom:"4px" }}
                    className="text-black"
                  >
                   <Skeleton />
                  </p>
                  <p style={{fontSize:"10px",lineHeight:"13px"}} className="text-white">Ewwwwwwe</p>
                </div>
              </div>
            </td>
            <td className="px-4 py-3 text-xs font-semibold ">
              <Skeleton />
            </td>
            <td className="px-4 py-3 text-xs "><Skeleton /></td>
            <td className="px-4 py-3 text-xs ">
              <span>
              
              <Skeleton />
              </span>
            </td>
            <td className="px-4 py-3 text-sm ">
              <button className="mr-4">
               <i className="fas fa-pen"></i>
              </button>
              <button>
               <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr className="text-gray-700">
            <td className="px-4 py-3 ">
              <div className="flex items-center text-sm">
                <div className="relative w-8 h-8 mr-3 rounded-full md:block">
                  <div
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  ></div>
                </div>
                <div>
                  <p
                    style={{ fontSize: "13px", lineHeight: "13px",marginBottom:"4px" }}
                    className="text-black"
                  >
                   <Skeleton />
                  </p>
                  <p style={{fontSize:"10px",lineHeight:"13px"}} className="text-white">Ewwwwwwe</p>
                </div>
              </div>
            </td>
            <td className="px-4 py-3 text-xs font-semibold ">
              <Skeleton />
            </td>
            <td className="px-4 py-3 text-xs "><Skeleton /></td>
            <td className="px-4 py-3 text-xs ">
              <span>
              
              <Skeleton />
              </span>
            </td>
            <td className="px-4 py-3 text-sm ">
              <button>
               <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
  )
}

export default LoadingVisitorsTable;
