import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/user";
import LoadingTable from "../../pages/Manager/LoadingTable";
import ResearchTable from "../../pages/Manager/ResearchTable";
import Modal from "react-modal";
import { XIcon, ExclamationIcon, PlusIcon } from "@heroicons/react/solid";
import styles from "../../pages/Manager/Styles";
import AddResearch from "../../pages/Manager/AddResearch";
import { arrayRemove,doc, updateDoc } from "firebase/firestore"
import { db } from "../../db";

function Projects() {
  const [isActive,setIsActive] = React.useState(false);
  const { users } = useSelector((state) => state.users);
  const [index] = useState(2);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deletemodalIsOpen, setdeleteModal] = React.useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  function deleteRow(e) {
    setIsActive(true)
    const docRef = doc(db,'users',localStorage.getItem("wadiKey"));
    console.log(docRef)
    updateDoc(docRef,{
      projects:arrayRemove(e)
    }).then(()=>{
      setIsActive(false)
      window.location.reload()
    })
   
  }

  // function openModal() {
  //   setIsOpen(true);
  // }

  function closeModal() {
    setIsOpen(false);
  }

  function closedeleteModal() {
    setdeleteModal(false);
  }

  function addProject(){
    window.location.href="/publish-it"
  }

  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Cards */}
        <div>
          {/* swerve here */}
          <LoadingOverlay active={isActive} spinner text="Loading...">
            <p>.</p>
            <>
              <div className="flex justify-between">
                <div>
                  <p className="my-4 text-xl font-bold">Projects Overview</p>
                </div>
                <div>
                  <button
                    className="px-4 py-3 bg-blue-600 rounded-md text-white outline-none focus:ring-4 shadow-xl transform active:scale-x-75 transition-transform mx-5 flex"
                    onClick={()=>{addProject()}}
                  >
                    <PlusIcon className="w-3 h-3 mt-2" />

                    <span  className="ml-2">Create Project</span>
                  </button>
                </div>
              </div>
              <div className="mr-6 mb-8 overflow-hidden rounded-lg shadow-lg">
                <div className="mb-7 w-full overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="text-sm font-semibold text-left text-gray-900">
                        <th className="px-4 py-3">Project Name</th>
                        <th className="px-4 py-3">Date Created</th>
                        <th className="px-4 py-3">Status</th>
                        <th className="px-4 py-3">Progress</th>
                        <th className="px-4 py-3">Action</th>
                      </tr>
                    </thead>
                    {!users && <LoadingTable />}
                    {users && (
                      <ResearchTable
                        projects={users.projects}
                        index={index}
                        deleteRow={deleteRow}
                      />
                    )}
                  </table>
                </div>
                <Modal
                  isOpen={modalIsOpen}
                  appElement={document.getElementById("root") || undefined}
                  onRequestClose={closeModal}
                  style={styles.customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="flex p-2 justify-between">
                    <h1 className="font-bold" style={{ fontSize: "23px" }}>
                      Create Project
                    </h1>
                    <button
                      className="bg-red-600 rounded-full p-2"
                      onClick={closeModal}
                    >
                      <XIcon
                        style={{ color: "white" }}
                        className="text-center w-full w-4 h-4"
                      />
                    </button>
                  </div>
                  <div className="p-2">
                    <AddResearch />
                  </div>
                </Modal>

                {/* Are you sure you want to delete modal? */}
                <Modal
                  isOpen={deletemodalIsOpen}
                  appElement={document.getElementById("root") || undefined}
                  onRequestClose={closedeleteModal}
                  style={styles.customStyles}
                >
                  <div className="flex p-2 justify-between">
                    <h1 className="font-bold" style={{ fontSize: "23px" }}>
                      Delete ?{" "}
                    </h1>
                    <button
                      className="bg-red-600 rounded-full p-3"
                      onClick={closedeleteModal}
                    >
                      <XIcon
                        style={{ color: "white" }}
                        className="text-center w-full w-4 h-4"
                      />
                    </button>
                  </div>

                  <div className="p-2">
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h2
                              as="h3"
                              className="text-lg leading-6 font-medium text-gray-900"
                            >
                              Deactivate account
                            </h2>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to deactivate your
                                account? All of your data will be permanently
                                removed. This action cannot be undone.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                          // onClick={() => deleteEmployee()}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => setdeleteModal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* End of Modal */}
              </div>
            </>
          </LoadingOverlay>
        </div>
      </div>
    </main>
  );
}

export default Projects;
