import {  setDoc, doc } from "@firebase/firestore";
import React, { Component } from "react";
import { db } from "../../db";
import LoadingOverlay from "react-loading-overlay";
import slugify from "slugify";
import Swal from "sweetalert2";
import TipTap from "../../components/TipTap/TipTap";

export class Form extends Component {
  state = {
    title: "",
    collaborator: "",
    progress:"0.05",
    isActive:false,
    description:"",
    loading:false
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };


  setDescription = (e) => {
    this.setState({
      description:e
    })
  }


  handleSubmit = () => {
    this.setState({isActive:true})
    if(this.state.title === ""){
      Swal.fire({
        title: 'Question not submitted!',  
        text: "You need to enter your question",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      this.setState({isActive:false})
    }
    else if(this.state.description === ""){
      Swal.fire({
        title: 'Question not submitted!',  
        text: "You need to enter more information about your question for your question",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      this.setState({isActive:false})
    }
    else if(this.state.category === ""){
      Swal.fire({
        title: 'Question not submitted!',  
        text: "You need to select a question category",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      this.setState({isActive:false})
    }
   else{
      const slug =  slugify(this.state.title,{
        replacement: "-",
        remove: /[$*_+~.()'"!?\-:@]/g,
        lower: true,
       })
      const docRef = doc(db,'forum',slug);
      setDoc(docRef, {
        slug:slug,
        topic: this.state.title,
        description: this.state.description,
        name: this.props.user.displayName,
        image: this.props.user.photoURL,
        category: this.state.category,
        email:this.props.user.email,
        title:JSON.parse(localStorage.getItem('userData')).title,
        timestamp:Date.now(),
        votes:0,
        answers:[]
      }).then(() => {
        this.setState({isActive:true})
        window.location.reload();
      });
    }
  };

  render(){
    return (
      <LoadingOverlay active={this.state.isActive} spinner text="Loading...">
        <p>.</p>
        <>
          <div>
            <div className="">
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form className="mb-6">
                  <div className="flex relative w-full mb-9">
                    <div className="w-full h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Title
                      </label>
                      <input
                        id="title"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="What's the question?"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div>
                  <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Select Category
                      </label>
                  <select
                id="category"
                type="text"
                className="mb-4 w-6/7 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Select Category"
                onChange={this.handleChange}
              >
               
              <option defaultValue="">
                All Categories
              </option>
                 
              </select>



              </div>

                  <div className="flex relative w-full mb-9">
                    <div className="w-full h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                       Description
                      </label>
                      <TipTap loading={this.state.loading} setDescription={this.setDescription} submitQuestion={this.handleSubmit} />
                    </div> 
                  </div>

                </form>

              
              </div>
            </div>
          
          </div>
        </>
      </LoadingOverlay>
    );
  }
}

export default Form;
