import { SearchIcon } from '@heroicons/react/solid';
import React from 'react'
import tw from "twin.macro";
import "./hero.css"



const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
function SearchHero() {
    const [search, setSearch] = React.useState("")
    console.log(search)
    return (
        <div>
           <div className="bg-blue-600 searchHero">
           <div className="mt-6 relative w-full mb-3">
                <Input onChange={(e)=>setSearch(e.target.value)}  id="search" placeholder="Search our shop for quality research items">
                </Input>
                 <SearchIcon style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      </div>
           </div>
        </div>
    )
}

export default SearchHero
