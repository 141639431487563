import React, {useState,useEffect} from "react";
// import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import logo from "../../../images/logo.svg";
import googleIconImageSrc from "../../../images/google-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import {Link} from "react-router-dom"
import Swal from 'sweetalert2'
import { signInWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../../firebase-auth";
import LoadingOverlay from "react-loading-overlay";
import {EyeIcon,EyeOffIcon} from "@heroicons/react/solid"
import slugify from "slugify"

const Container = tw(ContainerBase)`min-h-screen bg-primary-500 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-md m-0  sm:my-16 bg-white text-gray-900 shadow sm:rounded-4xl flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-4/5 p-6 sm:p-5`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl text-center font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-lg font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const Form = tw.form`mx-auto max-w-lg`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
// const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-blue-100 text-center hidden lg:flex justify-center`;
// const IllustrationImage = styled.div`
//   ${props => `background-image: url("${props.imageSrc}");`}
//   ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
// `;

export default function Login(props){
  const [logoLinkUrl] = useState("/");
  const [headingText] = useState("Wadi Partner Sign In");
  const [socialButtons] = useState([
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign In With Google",
      url: "https://google.com"
    }
  ]);
  const [submitButtonText,setSubmitButtonText] = useState("Sign In");
  const [SubmitButtonIcon] = useState(LoginIcon);
  const [forgotPasswordUrl] = useState("/forgot-password")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isActive,setActive] = useState(false)
  const [passwordShown,setPasswordShown] = useState(false);

  function togglePassword(){
    setPasswordShown(!passwordShown)
  }

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (user){
        props.history.push("/vendor/dashboard")
      } 
    });
  })

  function googleSignup(){
    const auth = getAuth();
    const user = auth.currentUser;
    console.log(user);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken;
        console.log(token);
        props.history.push("/vendor/dashboard")
        window.location.reload()
        const slug = slugify(result.user.displayName,{
          replacement: "-",
          remove: /[$*_+~.()'"!?\-:@]/g,
          lower: true,
         })
        localStorage.setItem("wadiKey",slug)
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        const email = error.email;
        console.log(email);
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(credential);
      });
  }

  function handleSubmit(e){
    e.preventDefault()
    setActive(true)
    setSubmitButtonText("Loading...")
    try{
      signInWithEmailAndPassword(auth,email,password)
      .then((response)=>{
         localStorage.setItem('wadiKey',(slugify(response.user.displayName)))
         setSubmitButtonText("Sign In")
          props.history.push('/vendor/dashboard')
          window.location.reload()
      }).catch((error)=>{
        console.log(error.message)
        Swal.fire({
            title: 'Login Not Successful',  
            text: "Your email/password is wrong",  
            icon: 'error',  
            confirmButtonColor: '#eb3f3f',   
            confirmButtonText: 'Try again'
        })
        setActive(false)
        setSubmitButtonText("Sign In")
      })
    } catch(error){
        alert(error.message)
        setActive(false)
        setSubmitButtonText("Sign In")
    }
  }
 
return (
  <LoadingOverlay
  active={isActive}
  spinner
  text='Loading...'
  >
  <p>.</p>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage className="mt-12" src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <SocialButtonsContainer>
                {socialButtons.map((socialButton, index) => (
                  <SocialButton style={{cursor:"pointer"}} onClick={googleSignup} key={index}>
                    <span className="iconContainer">
                      <img src={socialButton.iconImageSrc} className="icon" alt=""/>
                    </span>
                    <span className="text">{socialButton.text}</span>
                  </SocialButton>
                ))}
              </SocialButtonsContainer>
              <Form className="mt-6">
                <Input type="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
                <div className="mt-6 relative mb-3">
                <Input onChange={(e)=>setPassword(e.target.value)} type={passwordShown ? "text" : "password"} id="password" placeholder="Password">
                </Input>
                {passwordShown && <EyeOffIcon onClick={togglePassword} style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      }
                 {!passwordShown && <EyeIcon onClick={togglePassword} style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      }
                      </div>
                <SubmitButton onClick={handleSubmit} type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              </Form>
              <p tw="mt-6 text-xs text-gray-600 text-center">
                <a href={forgotPasswordUrl} tw="border-b border-gray-500 border-dotted">
                  Forgot Password ?
                </a>
              </p>
              <p tw="mt-8 text-sm text-gray-600 text-center">
                Dont have an account?{" "}
                <Link to="/vendor/signup" tw="border-b border-gray-500 border-dotted">
                  Sign Up
                </Link>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        {/* <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer> */}
      </Content>
    </Container>
    </LoadingOverlay>
)
                }
