import React from 'react';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';

export default function App(props) {
   const config = {
    public_key: 'FLWPUBK-dbe851d7b426b96d183b36373de5959f-X',
    tx_ref: Date.now(),
    amount: props.userData.amount,
    currency: props.userData.currency,
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: props.userData.email,
      phonenumber: '2348167299743',
      name: props.userData.name,
    },
    customizations: {
      title: 'Wadi',
      description: 'Make payments to Wadi',
      logo: 'https://i.ibb.co/3v34KpL/Wadi-Logo-1.png',
    },
  };

  const fwConfig = {
    ...config,
    text: props.buttonName,
    callback: (response) => {
       console.log(response);
      closePaymentModal() // this will close the modal programmatically
    },
    onClose: () => {},
  };

  return (
    <div className="App">
      <FlutterWaveButton className="mt-7 my-5 rounded-xl shadow-lg text-white bg-blue-600 w-full rounded-xl px-8 py-4" {...fwConfig} />
    </div>
  );
}