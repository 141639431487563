import React from 'react';
// import "../Styles.css"
// import {LocationMarkerIcon} from '@heroicons/react/solid'
import { Link } from 'react-router-dom';

function courses(props) {
  return (
props.data.map((service,i)=>(
    <div className="m-3" key={i}>
    <div className={'imageCard animate__animated animate__slow animate__fadeInUp animate__delay-'+i+'s'}>
   <img src={service.image} alt="#" style={{width:"100%",objectFit:"cover",height:"250px",borderRadius:"16px 16px 0px 0px"}}/>
   <div className="cardtext">
   <p className="text-4xl">{service.title}</p>
   {/* {course.title.length<= 19 &&<p className="text-2xl">{course.title}</p>} */}
    {/* <span className="flex"><LocationMarkerIcon className="w-6 h-6"/> Virtual</span> */}
    <Link to={service.slug}>
    <button className="w-full bg-blue-600 text-white rounded-xl p-4 my-3 shadow-lg hover:bg-blue-100 hover:text-blue-600">Get Started</button>
    </Link>
  </div>
 </div>
 </div>
 
)) 
  )
}

export default courses;
