import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 text-center xl:mx-8 max-w-lg lg:max-w-xl`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded`
]);
const Category = tw.div`mt-4 text-center text-secondary-100 font-bold text-sm`;
const Title = tw.p`mt-2 text-center font-medium text-lg`;
const Link = tw.a`inline-block mx-auto text-center mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

const ThreeColSimpleWithImage = () => {
  const blogPosts = [
    {
      imageSrc:
        "https://i.ibb.co/93WPLPV/new-test-concentrated-skilled-researchers-wearing-uniform-doing-test-creating-vaccine-3.jpg",
      category: "Diverse Expertise",
      title: "Our Academic Consultants have considerable publishing experience and 80% of them are active academics, indicating that we have the right team to revise your manuscripts or convert your projects (dissertation or thesis) into publishable papers, following universally acceptable academic writing and publishing rules. ",
      url: "https://app.wadi.africa/signup"
    },
    {
      imageSrc:
        "https://i.ibb.co/gyxrZqq/side-view-male-researcher-laboratory-with-microscope.jpg",
      category: "World-class Reviewers",
      title: "The Consultants come from 7 countries (Nigeria, India, South Africa, Rwanda, Zimbabwe, Cameroon and Ethiopia), ensuring that talented researchers from across the globe will be at your service. ",
      url: "https://app.wadi.africa/signup"
    },
    {
      imageSrc:
        "https://i.ibb.co/48nVwS6/male-researcher-biotechnology-laboratory-with-tablet-1.jpg",
      category: "Numerous Fields",
      title: "25 fields of study (in both STEM and non-STEM) are well represented on our team. The fields include Engineering, Human Resource, Biology, Technology Operation...",
      url: "https://app.wadi.africa/signup"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Why PublishIT?</HeadingTitle>
          <HeadingDescription>Here are some reasons you should work with us</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
                <Link href={post.url}>Learn More</Link>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

export default ThreeColSimpleWithImage