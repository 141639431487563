import React, { useState,useEffect } from 'react';
import {onAuthStateChanged} from "firebase/auth"
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import { Link } from 'react-router-dom';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
// import DashboardAvatars from '../partials/dashboard/DashboardAvatars';
// import DashboardCard01 from '../partials/dashboard/DashboardCard01';
// import DashboardCard02 from '../partials/dashboard/DashboardCard02';
// import DashboardCard03 from '../partials/dashboard/DashboardCard03';
// import DashboardCard04 from '../partials/dashboard/DashboardCard04';
// import DashboardCard05 from '../partials/dashboard/DashboardCard05';
// import DashboardCard06 from '../partials/dashboard/DashboardCard06';
// import DashboardCard07 from '../partials/dashboard/DashboardCard07';
// import DashboardCard08 from '../partials/dashboard/DashboardCard08';
// import DashboardCard09 from '../partials/dashboard/DashboardCard09';
// import DashboardCard10 from '../partials/dashboard/DashboardCard10';
// import DashboardCard11 from '../partials/dashboard/DashboardCard11';
// import DashboardCard12 from '../partials/dashboard/DashboardCard12';
// import DashboardCard13 from '../partials/dashboard/DashboardCard13';
// import LabBottle from "../images/testtube.gif" 
// import TestTubes from "../images/testTubes.svg"
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../redux/user";
import {auth} from "../firebase-auth";
import Projects from '../components/table/projects';


function Dashboard(props) {
  
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { users } = useSelector((state) => state.users);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (!user){
        props.history.push("/login")
      } 
    });
  })

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar users={users} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header users={users} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <WelcomeBanner users={users} />

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Avatars */}
              {/* <h1 className="text-center mb-2">Collaborators:</h1>
              <DashboardAvatars /> */}

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
               
                
                {/* Add view button */}
                               
              </div>

            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6 mt-4">

            {/* <p className="font-bold text-2xl mb-5">Overview:</p> */}
              {/* Stacked bar chart (Sales VS Refunds) */}
              {/* <DashboardCard09 /> */}
              {/* Card (Customers) */}
              {/* <DashboardCard10 /> */}
              {/* Card (Reasons for Refunds) */}
              {/* <DashboardCard11 /> */}
              {/* Card (Recent Activity) */}
              </div>
              {/* Card (Income/Expenses) */}
              {/* <DashboardCard13 /> */}
            </div>
     
            <div
        className="flex overflow-x-scroll ml-4 mt-4 pb-10 hide-scroll-bar"
      >
        <div
          className="flex flex-nowrap"
        >
          <div className="inline-block px-3">
            <div
              className="w-64 text-center py-auto h-64 max-w-xs overflow-hidden rounded-lg shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
            >
              <Link to="/research-manager">
              <div className="py-20">
             {users && <p className="font-bold text-4xl"> {users.projects.length}</p>}
             <p>Active Projects</p>
             </div>
             </Link>
            </div>

          </div>
          <div className="inline-block px-3">
            <div
              className="bg-blue-600 text-center text-white w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
            >
              <Link to="/research-manager">
              <div className="py-20">
              {/* <img style={{position:"relative",zIndex:"10"}} src={LabBottle} alt="#" /> */}
              {users && <p className="font-bold text-4xl"> {users.projects.length}</p>}
             <p>Projects in Review</p>
             </div>
             </Link>
            
            </div>
          </div>
          <div className="inline-block px-3">
            <div
              className="w-64 text-center h-64 max-w-xs overflow-hidden rounded-lg shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
            >
             <Link to="/research-manager">
              <div className="py-20">
              {users && <p className="font-bold text-4xl"> 0</p>}
             <p>Accepted projects</p>
             </div>
             </Link>
            </div>
          </div>
          <div className="inline-block px-3">
            <div
              className="bg-blue-600 text-center text-white w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
            >
             <Link to="/research-manager">
              <div className="py-20">
              {users && <p className="font-bold text-4xl"> 0</p>}
             <p>Rejected Projects</p>
             </div>
             </Link>

            </div>
          </div>
        </div>
        </div>


        <Projects />


        </main>

      


      </div>
    </div>
  );
}

export default Dashboard;