import React, { useState,useEffect } from 'react'
import { db } from '../../db'
import { getDoc,updateDoc,doc,arrayUnion,arrayRemove } from 'firebase/firestore'
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "../../components/headers/light";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
// import { CircleSpinner } from "react-spinners-kit";
import { auth } from '../../firebase-auth';
import { onAuthStateChanged } from 'firebase/auth';
import moment from 'moment';
import { ArrowNarrowDownIcon,ArrowNarrowUpIcon } from '@heroicons/react/solid';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import { XIcon } from '@heroicons/react/solid';
import Signup from '../../components/auth/Signup';
import styles from './Styles'
import Skeleton from 'react-loading-skeleton'
import TipTap from '../../components/TipTap/TipTap';
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingOverlay from 'react-loading-overlay'


const Header = tw(HeaderBase)`max-w-none`;


export default function QuestionPage() {

  const Style = {
    a: {
      color: "blue",
      textDecoration: "underline"
    }
  }

    const [buttonRounded] = useState(true)
    const buttonRoundedCss = buttonRounded && tw`rounded-full`;
    const navLinks = [
        <NavLinks key={1}>
          <NavLink href="/">Home</NavLink>
          <NavLink href="/forum">Wadi Forum</NavLink>
          <NavLink href="/navigator">Wadi Navigator</NavLink>
          <NavLink href="/#testimonials">Testimonials</NavLink>
        </NavLinks>,
        <NavLinks key={2}>
          <NavLink href="/login" tw="lg:ml-12!">
            Login
          </NavLink>
          <PrimaryLink css={buttonRoundedCss} href="/signup">
            Sign Up
          </PrimaryLink>
        </NavLinks>
      ];
     
    const [question, setQuestion] = useState("")
    const [loading,setLoading] = useState(false)
    const [loader,setLoader] = useState(true)
    const [answer,setAnswer] = useState("")
    const [user,setUser] = useState("")
    const [signupModal,setsignupModal] = useState(false)

     useEffect(()=>{
        const docRef = doc(db, "forum",window.location.pathname.slice(7) );
         getDoc(docRef).then((response)=>{
           setQuestion(response.data())
           setLoader(false)
         })
     },[])


     useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
          setUser(user)
        });
      })


      function upvote(answer){
        if(user === null){
         Swal.fire({
           title:"You need to signup to upvote",
           icon:"error",
           confirmButtonText:"Alright",
           confirmButtonColor:"#0d60d8"
         })
        }else{
      setLoading(true)
        const docRef = doc(db, "forum",window.location.pathname.slice(7));
        updateDoc(docRef,{
           answers:arrayRemove(answer)
        })
        .then(()=>{
       answer.upvote = answer.upvote+1

        updateDoc(docRef, {
            answers:arrayUnion(answer)
        })
       })
       .then(()=>{
         window.location.reload()
       })  
      }
    }


      function closeSignupModal(){
        setsignupModal(!signupModal)
      }

      function downvote(answer){
        if(user === null){
          Swal.fire({
            title:"You need to signup to downvote",
            icon:"error",
            confirmButtonText:"Alright",
            confirmButtonColor:"#0d60d8"
          })
         }
        else{
          setLoading(true)
          const docRef = doc(db, "forum",window.location.pathname.slice(7));
        updateDoc(docRef,{
           answers:arrayRemove(answer)
        }).then(()=>{
       answer.downvote = answer.downvote+1
        updateDoc(docRef, {
            answers:arrayUnion(answer)
        })
       }).then(()=>{
        window.location.reload()
       })  
      }
    }

    function sendAnswers(e){
      setAnswer(e)
      const answerValue = e
      setAnswer(answerValue)

      
    }

     function submitAnswer(){
       if(user === null){
        setsignupModal(!signupModal)
       }

       else if(answer === ""){
         Swal.fire({
           title:"Answer not detected",
           text:'You need to enter an answer',
           icon: "error",
           confirmButtonText: "Okay",
           confirmButtonColor:"#0d60d8"
         })
       }else{
        const answerPayLoad = {
            name:user.displayName,
            answer:answer,
            email:user.email,
            title:JSON.parse(localStorage.getItem('userData')).title,
            image:user.photoURL,
            upvote:0,
            downvote:0,
            timestamp:Date.now()
        }
        setLoading(true)
        const docRef = doc(db, "forum",window.location.pathname.slice(7));
        updateDoc(docRef,{
            answers:arrayUnion(answerPayLoad)
        }).then(()=>{
            setLoading(false)
            window.location.reload()
        })   
      }
     }
  

    


  
        return (
          <LoadingOverlay
  active={loading}
  spinner
  text='Loading...'
  >
            <div style={Style}>
                <Header links={navLinks} className="lg:px-12 p-6" />
                <div className="p-5 lg:w-3/4 lg:m-auto">
                  <button onClick={()=>window.history.go(-1)}> <i className="fas fa-arrow-left mr-2"></i>Back</button>

                  {loader && <div className="p-4 shadow-lg">
                        <p className="mt-2 font-bold text-2xl"><Skeleton/></p>
                        <p className="text-xs">Asked on: <Skeleton /></p>
                        <p><Skeleton count={2}/></p>
                        <div className="mt-3">
                            <div className="flex">
                              <div>
                              <Skeleton/>
                              </div>
                              <div>
                                <p className="ml-2 mt-2"><Skeleton/></p></div>
                            </div>
                          </div>
                    </div>
                    } 


                    {question && <div className="questionCard p-4 rounded-lg shadow-lg">
                        <p className="mt-2 font-bold text-2xl">{question.topic}</p>
                        <p className="text-xs">Asked on: {moment(question.timestamp).format("lll")}</p>
                        <div dangerouslySetInnerHTML={{__html: question.description}}></div>
                        <div className="mt-3">
                            <div className="flex">
                              <div>
                                <img className="h-10 w-10 rounded-full" src={question.image} alt="" />
                              </div>
                              <div className="mb-3">
                                <p className="ml-2" style={{marginBottom:"-10px"}}>{question.name}</p>
                                <span className="ml-2 text-xs" style={{fontSize:"10px"}}>{question.title}</span>
                                </div>
                            </div>
                          </div>
                    </div>}

                    {question && question.answers.length === 1 && <p className="py-3 font-bold text-xl mt-3">{question.answers.length} Answer</p>}
                    {question && question.answers.length >= 2 && <p className="py-3 font-bold text-xl mt-3">{question.answers.length} Answers</p>}
                    <form>

                    {question && question.answers.map((answer,i)=>(
                        <div key={i}>
                         <div className="answerCard p-5 shadow-md mb-4 rounded-lg">
                         <div className="flex">
                              <div>
                                <img className="h-10 w-10 rounded-full" src={answer.image} alt="" />
                              </div>
                              <div className="mb-2 ml-3">
                                <p className="mt-2 text-sm" style={{marginBottom:"-9px"}}>{answer.name}</p>
                                <span className="text-xs" style={{fontSize:"10px"}}>{answer.title}</span>
                                </div>
                            </div>
                             <div dangerouslySetInnerHTML={{__html: answer.answer}}></div>
                             <div className="flex justify-between">
                             <div className="mt-3 ">
                            
                            <div className="flex justify-center">
                            <div>
                            <p style={{}} className="text-xs mr-9">{moment(answer.timestamp).format('LLL')}</p>
                            </div>
                            <div className="flex">
                              <div className="flex">
                            <div onClick={()=>downvote(answer)}>
                              <ArrowNarrowDownIcon className="w-4 h-4" />
                              </div>
                              <div>
                              <div>{answer.downvote}</div>
                              <p className="text-xs">downvotes</p>
                              </div>
                              </div>
                            
                              <div className="ml-2 flex">
                            <div onClick={()=>upvote(answer)}>
                              <ArrowNarrowUpIcon className="w-4 h-4" />
                              </div>
                              <div>
                              <div>{answer.upvote}</div>
                              <p className="text-xs">upvotes</p>
                              </div>
                              </div>
                          </div>
                          </div>


                          </div>
                        
                          </div>
                         </div>

                        </div>
                    ))}
                    <div className="mt-8 flex relative w-full mb-9">
                    <div className="w-full h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                       Submit your answer
                      </label>
                      <TipTap loading={loading} setDescription={sendAnswers} submitQuestion={submitAnswer} />
                    </div> 
                  </div>
                  </form>

                  <Modal
                  isOpen={signupModal}
                  appElement={document.getElementById('root') || undefined}
                  onRequestClose={closeSignupModal}
                  contentLabel="Signup Modal"
                  style={styles.customStyles}
                >
                  <div className="flex p-2 justify-between">
                    <button className="bg-blue-600 rounded-full p-2" onClick={closeSignupModal}>
                      <XIcon style={{ color: "white" }} className="text-center w-full w-4 h-4" />
                    </button>
                  </div>
                  <div className="p-2">
                    <Signup />
                  </div>
                </Modal>

                  {/* <div style={{marginTop:"150px"}} className="text-center">
                    <button
                      className="flex justify-center w-full rounded-lg bg-blue-600 p-3 text-white"
                      onClick={submitAnswer}
                    >
                   
                   <CircleSpinner loading={loading} /> 
                   <span className="ml-4">Submit Answer </span>
                    </button>
                  </div> */}



                </div>
            </div>
            </LoadingOverlay>
        )
    }