const customStyles = {
    overlay:{
      backgroundColor: 'rgba(52, 52, 52, 0.8)'
      
    },
  
    content:
    {
      position:"fixed",
      top:"50%",
      left:"50%",
      width:"74%",
      height:"430px",
      transform: "translate(-50%, -50%)",
      zIndex:20
    },
  };


  const styles= {
     customStyles:customStyles
  }

  export default styles