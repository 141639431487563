import React, {useState,useEffect} from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "../../images/logo.svg";
import googleIconImageSrc from "../../images/google-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import {EyeIcon,EyeOffIcon} from "@heroicons/react/solid"
import {Link} from "react-router-dom"
import {db} from "../../db"
import { doc,getDoc,setDoc } from 'firebase/firestore'
import {createUserWithEmailAndPassword,updateProfile,getAuth,GoogleAuthProvider,signInWithPopup,onAuthStateChanged} from 'firebase/auth'
import {auth} from '../../firebase-auth'
import Swal from "sweetalert2"
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay'


const Container = tw(ContainerBase)`min-h-screen bg-primary-500 text-white font-medium flex justify-center -m-12`;
const Content = tw.div`m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`  p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


export default function Signup(props){
  const [logoLinkUrl] = useState("/");
  const [headingText] = useState("Sign up on Wadi");
  const [socialButtons] = useState([
    {
      iconImageSrc: googleIconImageSrc,
      text: "Continue With Google",
    }
  ]);
  const [submitButtonText] = useState("Sign Up");
  const [firstName,setfirstName] = useState("");
  const [lastName,setlastName] = useState("");
  const [password,setPassword] = useState("");
  const [email,setEmail] = useState("");
  const [SubmitButtonIcon] = useState(SignUpIcon);
  const [tosUrl] = useState("#");
  const [privacyPolicyUrl] = useState("#");
  const [passwordShown,setPasswordShown] = useState(false);
  const [isActive,setActive] = useState(false)
  const [image] = useState("https://firebasestorage.googleapis.com/v0/b/tellbookstore.appspot.com/o/avatar.png?alt=media&token=593af39b-6c6d-47d8-afc5-ab8560b08dd2")
  function togglePassword(){
    setPasswordShown(!passwordShown)
  };

  // useEffect(()=>{
  //   const auth = getAuth()
  //   onAuthStateChanged(auth, (user) =>{
  //  if(user != null){
  //    props.history.push("/dashboard")
  //  }
  // })
  // },[props])

  function googleSignup(e){
    e.preventDefault()
    const auth = getAuth();
    const user = auth.currentUser;
    console.log(user);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result.user)
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log(token);
        console.log(result.user)
        addToDatabase(result.user)
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        const email = error.email;
        console.log(email);
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(credential);
      });
})
  }


  async function addToDatabase(e){
          console.log(e)
          const slug = e.displayName.toLowerCase().replace(" ","-")
          const docRef = doc(db,'users',(slug));
          const docSnap = await getDoc(docRef);
          if (docSnap._document != null) {
            Swal.fire({
              title: "Username already taken",
              text:
                "This name has already been taken. Please use another one",
              icon: "error",
              confirmButtonText: "Okay"
            });
            setActive(false)
          } else {
            setDoc(docRef, {
              firstName: e.displayName.split(" ")[0],
              lastName: e.displayName.split(" ")[1],
              email: e.email,
              phoneNumber:e.phoneNumber,
              image:e.photoURL,
              reports:[],
              projects:[],
              purchases:[],
              messages:[],
              forum:[],
              notifications: [
                {
                  title: "Welcome to Wadi!",
                  message:
                    "Congratulations, You have successfully created an account on Wadi",
                  timestamp: moment(Date.now()).format("LLL")
                }
              ],
              uid: e.uid,
              photoURL:e.photoURL
            }).then(()=>{
              localStorage.setItem('wadiKey',(slug))
                Swal.fire({  
                  title: 'Registration Successful!',  
                  text: "You have successfully created an account on Wadi",  
                  icon: 'success',  
                  confirmButtonColor: '#eb3f3f',   
                  confirmButtonText: 'Ok'
                }); 
                setTimeout(()=>{
                  props.history.push('/dashboard')
                  window.location.reload()
                },3000)
                
            })
            .catch(error => {
                console.log(error);
                 Swal.fire({
                  title: "Something went wrong!",
                  text:
                    "Please try again by refreshing the page. Sorry for the incoveniences 😥",
                  icon: "error",
                  confirmButtonText: "Ok"
                })
              })
          }
  }

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (user){
        props.history.push("/dashboard")
      } 
    });
  })

  function Submit(e){
    e.preventDefault();
    setActive(true)
  // this.setState({createButton:"Loading..."})
   if(firstName === ''){
    Swal.fire({  
      title: 'Registration Invalid',  
      text: "Please enter your first name",  
      icon: 'error',  
      confirmButtonColor: '#eb3f3f',   
      confirmButtonText: 'Ok'
    }); 
  setActive(false)
  // this.setState({createButton:"Create Account"})
   }
   else if(lastName === ''){
    Swal.fire({  
      title: 'Registration Invalid',  
      text: "Please enter your last name",  
      icon: 'error',  
      confirmButtonColor: '#eb3f3f',   
      confirmButtonText: 'Ok'
    }); 
    setActive(false)
    // this.setState({createButton:"Create Account"})
   }
   else if(email === ''){
    Swal.fire({  
      title: 'Registration Invalid',  
      text: "Please enter your email",  
      icon: 'error',  
      confirmButtonColor: '#eb3f3f',   
      confirmButtonText: 'Ok'
    }); 
    setActive(false)
   }
   else if(password === ''){
    Swal.fire({  
      title: 'Registration Invalid',  
      text: "Please enter your password",  
      icon: 'error',  
      confirmButtonColor: '#eb3f3f',   
      confirmButtonText: 'Ok'
    }); 
    setActive(false)
   }

 else{
    try{
      const slug = firstName.toLowerCase()+"-"+lastName.toLowerCase()
      console.log(slug)
      createUserWithEmailAndPassword(auth,email,password)
      .then(async()=>{
        const docRef = doc(db,'users',(slug));
        const docSnap = await getDoc(docRef);
        if (docSnap._document != null) {
          Swal.fire({
            title: "Username already taken",
            text:
              "This name has already been taken. Please use another one",
            icon: "error",
            confirmButtonText: "Okay"
          });
          setActive(false)
        } else {
          setDoc(docRef, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber:"",
            image:image,
            reports:[],
            projects:[],
            purchases:[],
            research:[],
            messages:[],
            forum:[],
            notifications: [
              {
                title: "Welcome to Visitor App!",
                message:
                  "Congratulations, You have successfully created an account on Visitor",
                timestamp: moment(Date.now()).format("LLL")
              }
            ],
            uid: auth.currentUser.uid,
            photoURL:image
          }).then(()=>{
            localStorage.setItem('wadiKey',(slug))
            updateProfile(auth.currentUser, {
              displayName: firstName+" "+lastName,
              photoURL: image
            }).then(()=>{
              Swal.fire({  
                title: 'Registration Successful!',  
                text: "You have successfully created an account on Wadi",  
                icon: 'success',  
                confirmButtonColor: '#eb3f3f',   
                confirmButtonText: 'Ok'
              }); 
              props.history.push('/dashboard')
              window.location.reload()
            })
            
          })
            .catch(error => {
              console.log(error);
               Swal.fire({
                title: "Something went wrong!",
                text:
                  "Please try again by refreshing the page. Sorry for the incoveniences 😥",
                icon: "error",
                confirmButtonText: "Ok"
              });
            });
        }
      }).catch((error) =>{
        if(error.message === "Firebase: Error (auth/email-already-in-use)."){
          Swal.fire({  
            title: 'Registration Invalid',  
            text: "This email has already been used by another account",  
            icon: 'error',  
            confirmButtonColor: '#eb3f3f',   
            confirmButtonText: 'Ok'
          })
          setActive(false)
        } else if (error.message === "Firebase: Error (auth/invalid-email)."){
          Swal.fire({  
            title: 'Registration Invalid',  
            text: "Password should be at least 6 characters",  
            icon: 'error',  
            confirmButtonColor: '#eb3f3f',   
            confirmButtonText: 'Ok'
          })
          setActive(false)
        }
      })
      
    } catch(error){
      console.log(error.message)
     if(error.message === "Firebase: Error (auth/email-already-in-use)."){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "This email has already been used by another account",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
    } else if (error.message === "Firebase: Password should be at least 6 characters (auth/weak-password)."){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Password should be at least 6 characters",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
    } else if (error.message === "Firebase: Error (auth/invalid-email)."){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Password should be at least 6 characters",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      setActive(false)
    }
    else{
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Password should be at least 6 characters",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      setActive(false)
    }
   
    }
  }
  }


return (
  <LoadingOverlay
  active={isActive}
  spinner
  text='Loading...'
  >
  <p>.</p>
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <SocialButtonsContainer>
                {socialButtons.map((socialButton, index) => (
                  <SocialButton style={{cursor:"pointer"}} onClick={googleSignup} key={index}>
                    <span className="iconContainer">
                      <img src={socialButton.iconImageSrc} className="icon" alt="" />
                    </span>
                    <span className="text">{socialButton.text}</span>
                  </SocialButton>
                ))}
              </SocialButtonsContainer>
              <Form className="mt-4">
              <div className="relative w-full mb-2">
                <div className="mb-6">
               <Input onChange={(e)=>setfirstName(e.target.value)} type="name" id="name" placeholder="First Name" />
               </div>
               <div>
               <Input onChange={(e)=>setlastName(e.target.value)} type="name" id="name" placeholder="Last Name" />
               </div>
               </div>

                <Input onChange={(e)=>setEmail(e.target.value)} type="email" id="email" placeholder="Email" />
                <div className="mt-6 relative w-full mb-3">
                <Input onChange={(e)=>setPassword(e.target.value)} type={passwordShown ? "text" : "password"} id="password" placeholder="Password">
                </Input>
                {passwordShown && <EyeOffIcon onClick={togglePassword} style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      }
                 {!passwordShown && <EyeIcon onClick={togglePassword} style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      }
                      </div>
                <SubmitButton onClick={Submit} type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
                <p tw="mt-6 text-xs text-gray-600 text-center">
                  I agree to abide by Wadi's{" "}
                  <a href={tosUrl} tw="border-b border-gray-500 border-dotted">
                    Terms of Service
                  </a>{" "}
                  and its{" "}
                  <a href={privacyPolicyUrl} tw="border-b border-gray-500 border-dotted">
                    Privacy Policy
                  </a>
                </p>

                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Already have an account?{" "}
                  <Link to="/login" tw="border-b border-gray-500 border-dotted">
                    Sign In
                  </Link>
                </p>
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
    </AnimationRevealPage>
  </LoadingOverlay>
)
}
